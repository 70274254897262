.faq-container {
  max-width: 1050px;
  margin: 0 auto;

  padding: 120px 60px;
}

html, body {
  height: 100%;
}

.faq-header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;

  color: #182129;

  text-align: center;
  margin-bottom: 50px;
}

.faq-items {

}

.faq-item {
  //padding: 30px;
  overflow: hidden;
  border-top: 2px solid rgba(24, 33, 41, 0.3);
  cursor: pointer;

  &:last-child {
    border-bottom: 2px solid rgba(24, 33, 41, 0.3);
  }

  &.active {
    img {
      transform: rotate(45deg);
    }

  }
}

.faq-item-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  //margin-bottom: 35px;
  margin: 30px 0;

  & > img {
    transition: 0.2s ease-in-out;
  }
}

.faq-item-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 136.52%;
  /* or 27px */

  padding-right: 30px;
  color: rgba(24, 33, 41, 0.8);
}


.faq-item.active {
  .faq-item-bottom {
    max-height: 250px;
    //height:100%;
  }
}

.faq-item-bottom {
  max-height: 0;
  height: auto;
  transition: 0.3s linear;
}

.faq-item-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 136.52%;
  /* or 25px */

  padding-bottom: 30px;
  color: rgba(24, 33, 41, 0.7);
}

@media (max-width: 560px) {
  .faq-container {
    padding: 30px 20px;
  }

  .faq-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;

    color: #182129;
    margin-bottom: 30px;
  }

  .faq-item-title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 136.52%;
    color: rgba(24, 33, 41, 0.8);

  }

  .faq-item-top {
    margin: 15px 0;
    & > img {
      width: 11px;
      height: 11px;
    }
  }

  .faq-item-text {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 136.52%;
    /* or 14px */

    padding-bottom: 15px;
    color: rgba(24, 33, 41, 0.7);
  }
}