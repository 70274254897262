html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none;
}

html {
  height: 101%;
}

/* always display scrollbars */
body {
  font-size: 62.5%;
  line-height: 1;
  font-family: 'Inter', sans-serif;
  //background: #DDDBDB;

  //&.show-menu {
  //  overflow: hidden;
  //}
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

strong {
  font-weight: bold;
}

input {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

//img { border: 0; max-width: 100%; }

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  // text-decoration: underline;
}
//a.text:active, /* активная/посещенная ссылка */
//a.text:hover,  /* при наведении */
//a.text {
//  text-decoration: none;
//  //color: #666;
//}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
}
