.section-advantages {
  padding: 0 60px;
}

.section-advantages-title {
  margin: 120px 0 50px 0;
  display: flex;
  justify-content: space-around;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;

  color: #182129;
}

.section-advantages-list {
  margin: 0 auto 60px auto;
  max-width: 1600px;
  //display: flex;
  //flex-direction: row;
  //flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(4, 377px);
  grid-gap: 20px;
  justify-content: center;
}

.section-advantages-list-item {
  background: #DADEE3;
  border-radius: 20px;
  min-width: 377px;
  width: 377px;
  margin-bottom: 20px;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.section-advantages-list-item-middleware {
  padding: 24px;
}

.section-advantages-list-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 136.52%;
  /* identical to box height, or 25px */

  text-transform: uppercase;

  color: #142C4F;
  margin: 50px 0 15px 0;
}

.section-advantages-list-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 136.52%;
  /* or 27px */

  color: #182129;
}

.section-advantages-nav {
  max-width: 1600px;
  margin: 0 auto 100px auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  //display: grid;
  //grid-template-columns: repeat(4, max-content);
  //justify-content: center;
  //grid-gap: 20px;
}

.section-advantages-nav-item {
  background: #DADEE3;
  border-radius: 35px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #182129;

  //display: flex;
  //justify-content: center;
  //align-items: center;
  //display: grid;
  //grid-template-columns: repeat(2,auto );
  //justify-content: center;
  //grid-gap: 20px;
  padding: 20px 45px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 25px;
  }

  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .section-advantages-list {
    grid-template-columns: repeat(3, 377px);
  }
  .section-advantages-nav {
    grid-template-columns: repeat(3, max-content);
  }
}

@media (max-width: 1200px) {
  .section-advantages-list {
    grid-template-columns: repeat(2, 300px);
  }
  .section-advantages-list-item {
    width: 300px;
    min-width: 300px;
    margin-right: 10px !important;
  }
  .section-advantages-nav {
    grid-template-columns: repeat(2, max-content);
  }
}

@media (max-width: 850px) {
  .section-advantages {
    padding: 0 30px;
  }
  .section-advantages-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;

    color: #182129;
    margin-top: 60px;
  }

  .section-advantages-list {
    margin-bottom: 30px;
    //justify-content: flex-start;
  }

  .section-advantages-list-item-middleware {
    padding: 12px;
  }
  .section-advantages-list-item {
    width: 200px;
    min-width: 200px;
    margin-right: 10px !important;
  }
  .section-advantages-list {
    grid-template-columns: repeat(2, 200px);
  }
  .section-advantages-list-title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 136.52%;
    text-transform: uppercase;
    margin: 20px 0 15px 0;
  }

  .section-advantages-list-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 136.52%;
    color: #182129;
  }
  .section-advantages-list-item-middleware {
    & > img {
      width: 32px;
      height: 32px;
    }
  }

  .section-advantages-nav {
    margin-bottom: 30px;
  }

  .section-advantages-nav-item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #182129;
    padding: 8px 25px;
  }
}


@media (max-width: 560px) {
  .section-advantages-list-item {
    width: 160px;
    min-width: 160px;
    margin-right: 5px !important;
  }
  .section-advantages-list {
    grid-template-columns: repeat(2, 160px);
  }
  .section-advantages-nav {
    //display: flex;
    //flex-direction: row;
    //flex-wrap: wrap;
    //justify-content: center;
    &>div {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 350px) {
  .section-advantages-list-item {
    width: 140px;
    min-width: 140px;
    margin-right: 5px !important;
  }
  .section-advantages-list {
    grid-template-columns: repeat(2, 140px);
  }
}