.section-materials {
  padding: 50px 16px 40px 16px;
  background: #182129;
}

.section-materials-container {
  max-width: 1650px;
  margin: 0 auto;
}

.section-materials-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;

  color: #FFFFFF;
  text-align: center;
  margin: 0 auto 50px auto;
}

.section-materials-items {
  //display: flex;
  //flex-direction: row;
  //flex-wrap: wrap;
  //justify-content: center;
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  grid-template-columns: repeat(3, max-content);
}

.section-materials-item {
 // width: 100%;
  max-width: 500px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 300px;
  width: 377px;
  //margin-bottom: 20px;
  //margin-right: 20px;
  //&:nth-child(3n+3){
  //  margin-right: 0;
  //}
}

.section-materials-item-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  margin: 0 10px;
  color: #FFFFFF;
}
@media(max-width: 1572px) {
  //.section-materials-item {
  //  margin-right: 20px !important;
  //
  //  &:nth-child(2n+2) {
  //    margin-right: 0;
  //  }
  //}
  .section-materials-items {
    grid-template-columns: repeat(2, max-content);
  }
}

@media(max-width: 1100px) {
  .section-materials-item {
    max-width: 350px;
    min-height: 200px;
    //margin-right: 10px !important;
    //margin-bottom: 10px !important;
    //
    //&:nth-child(2n+2) {
    //  margin-right: 0 !important;
    //}
  }  .section-materials-item-text {
       font-style: normal;
       font-weight: 700;
       font-size: 20px;
       line-height: 23px;
       text-transform: uppercase;
     }
}

@media(max-width: 768px) {
  .section-materials-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .section-materials-item-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }

  .section-materials-item {
    max-width: 250px;
    min-height: 150px;
    //margin-right: 10px !important;
    //margin-bottom: 10px !important;
    //
    //&:nth-child(2n+2) {
    //  margin-right: 0 !important;
    //}
  }
}


@media (max-width: 560px) {
  .section-materials-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .section-materials-item {
    max-width: 235px;
    min-height: 120px;
    //margin-right: 10px !important;
    //margin-bottom: 10px !important;
    //
    //&:nth-child(2n+2) {
    //  margin-right: 0;
    //}
  }
  .section-materials-item-text {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
}

@media (max-width: 520px) {
  .section-materials-items {
      grid-template-columns: repeat(1, max-content);
  }

  .section-materials-item {
    max-width: 250px;
    min-height: 150px;
    //margin-right: 0 !important;
  }
  .section-materials-item-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }
}
