.preloader-background {
  position: fixed;
  background: #FFF;
  width: 101vw;
  height: 100vh;
  z-index: 1000;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &.visible {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
    //display: none;
  }
}

.preloader-content {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.logo-full {
  background: url(../../../public/images/logo_dark.svg) no-repeat;
  width: 187px;
  height: 59px;
  margin-bottom: 60px;
}



.logo-subtext {
  margin-top: 13px;
}

@media (max-width: 1250px) {
  .preloader-content {
    img {
      zoom: 0.77;
    }
  }
}

@media (max-width: 650px) {
  .preloader-content {
    img {
      zoom: 0.42;
    }
  }
}