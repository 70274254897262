.section-example {
  background: linear-gradient(180deg, #142C4F 0%, #182129 99.94%);
}

.section-example-container {
  margin: 0 auto;
  max-width: 1600px;
  padding: 50px 16px 120px 16px;
}

.section-example-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;

  color: #FFFFFF;
  margin-bottom: 50px;
}

.section-example-items {
  //display: flex;
  //flex-direction: row;
  //flex-wrap: wrap;
  //justify-content: center;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, max-content);
  justify-content:center;
}

.section-example-item {
  width: 100%;
  max-width: 500px;
  text-align: center;
  //display: flex;
  //align-items: center;
  //justify-content: center;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 300px;
  //margin-bottom: 20px;
  //
  //margin-right: 20px;
  &:nth-child(3n+3) {
   // margin-right: 0;
  }
}

@media(max-width: 1572px) {
  .section-example-items {
    grid-template-columns: repeat(2, max-content);
  }
  //.section-example-item {
  //  margin-right: 20px !important;
  //
  //  &:nth-child(2n+2) {
  //    margin-right: 0 !important;
  //  }
  //}
}

@media(max-width: 1100px) {
  .section-example-item {
    max-width: 350px;
    min-height: 200px;
    //margin-right: 10px !important;
    //margin-bottom: 10px !important;
    //
    //&:nth-child(2n+2) {
    //  margin-right: 0 !important;
    //}
  }
}

@media(max-width: 768px) {
  .section-example-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .section-example-item-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }

  .section-example-item {
    max-width: 250px;
    min-height: 150px;
    //margin-right: 10px !important;
    //margin-bottom: 10px !important;
    //
    //&:nth-child(2n+2) {
    //  margin-right: 0 !important;
    //}
  }
}


@media (max-width: 560px) {
  .section-example-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .section-example-item {
    max-width: 235px;
    min-height: 120px;
    margin-right: 10px !important;
    margin-bottom: 10px !important;

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }
  .section-example-item-text {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  .section-example-item {
    grid-template-columns: repeat(1, max-content);
  }

  .section-example-items {
    grid-gap: 10px;
    grid-template-columns: repeat(1, max-content);
  }
}

@media (max-width: 520px) {
  .section-example-items {
    flex-direction: column;
    align-items: center;
  }

  .section-example-item {
    max-width: 250px;
    min-height: 150px;
    margin-right: 0 !important;
  }
  .section-example-item-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }
}