footer {
  padding: 40px 80px;
  background: #142C4F;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
}

.footer-left {

}

.header-left-logo {
  width: 180px;
  height: 60px;

}

.footer-right {
  display: flex;
  flex-direction: row;

}

.footer-right-address {
  &:first-child {
    margin-right: 20px;
  }
}

.footer-right-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;

  color: #FFFFFF;
  margin-bottom: 30px;
  display: flex;
}

.footer-right-text1 {
  width: 400px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #FFFFFF;
}

.footer-right-contacts {

}

.footer-right-data {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.footer-right-text2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  &:first-child {
    margin-bottom: 15px;
  }

  color: #FFFFFF;
}

.made-by {
  color: #142C4F;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-align: center;
}

@media (max-width: 1200px) {
  .footer-right {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-right-address {
    margin-bottom: 20px;
  }

  .footer-right-data {
    align-items: flex-start;
  }
}

@media (max-width: 850px) {
  footer {
    padding: 20px 40px;
  }
  .header-left-logo {
    width: 112px;
    height: 35px;
  }
  .footer-right-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;

    margin-bottom: 15px;
  }

  .footer-right-text1 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    width: 200px;
  }

  .footer-right-text2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px ;
    line-height: 16px;

    color: #FFFFFF;
  }

  .footer-right-data {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #FFFFFF;
  }
}

@media (max-width: 650px) {
  footer {
    padding: 20px 40px;
  }
  .header-left-logo {
    width: 112px;
    height: 35px;
  }
  .footer-right-title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
  }

  .footer-right-text1 {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
  }

  .footer-right-data {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    color: #FFFFFF;
  }
  .footer-right-text2 {
    margin-right: 0 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px ;
    line-height: 12px;

    color: #FFFFFF;

  }
}

@media (max-width: 450px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-left {
    margin-bottom: 20px;
  }
}