.notification-container {
  padding: 32px 40px;
  background-color: #fff;
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 5;
  border-radius: 56px 56px 0px 56px;
  max-width: 400px;
  box-shadow: 2px 6px 16px rgba(0, 0, 0, 0.25);
  transform: translateX(800px);
  transition: transform 0.3s ease-in-out;

  &.visible {
    transform: translateX(0);
  }

  &.toBook {
    // padding-top: 40px;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    //padding: 10px;
    zoom: 2;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #f2f3f4;
      // bacfilter: opacity(0.1);
    }
  }
}

.notification-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #182129;

  //margin-top: 30px;
  //padding-right: 30px;
}

.notification-button {
  padding: 16px 40px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.08em;

  color: #182129;
  background-color: #F7CF46;
  border-radius: 32px;
  text-align: center;
  margin-top: 32px;
  cursor: pointer;
}

@media (max-width: 1500px) {
  .notification-container {
    padding: 30px;
    right: 40px;
    //max-width: 300px;
  }

  .notification-text {

  }
}

@media (max-width: 1250px) {
  .notification-container {
    //padding: 20px;
    right: 20px;
    max-width: 300px;
  }

  .notification-text {
    font-size: 24px;
    line-height: 32px;
  }

  .notification-container.toBook {
    //padding-top: 30px;
  }
}

@media (max-width: 650px) {
  .notification-container {
    //padding: 20px;
    transform: translateX(400px);
    right: 10px;
    max-width: 250px;
    bottom: 50px;
  }

  .notification-text {
    font-size: 20px;
    line-height: 28px;
  }

  .notification-button {
    padding: 10px 32px;
    font-size: 14px;
  }

  .notification-container.toBook {
    //padding-top: 30px;
  }
}