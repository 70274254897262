.work-order {
  padding: 50px 60px 65px 60px;
  background: #142C4F;
}

.work-order-container {
  max-width: 1600px;
  margin: 0 auto;
}

.work-order-title {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;

  color: #FFFFFF;

  margin-bottom: 50px;
}

.work-order-items {
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.work-order-item {
  display:flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 50px;
    &:before {
    background: #DADEE3;
    content: "";
      height: calc(100% - 3px);
      left: 27px;
      position: absolute;
      top: 55px;
      width: 3px;
  }
  }

}

.work-order-circle {
  position: relative;
  padding: 25px;
  border: 3px solid #DADEE3;
  border-radius: 86px;
}

.work-order-number {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.work-order-item-text {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin-left: 20px;
}

@media (max-width: 650px) {
  .work-order {
    padding: 50px 20px 65px 20px;
    background: #142C4F;
  }

  .work-order-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .work-order-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .work-order-item-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    height: 30px;
    color: #FFFFFF;
  }

  .work-order-circle {
    padding: 9px;
    border: 2px solid #DADEE3;
  }

  .work-order-number {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  .work-order-item:not(:last-child):before {
    background: #DADEE3;
    content: "";
    height: calc(100%);
    left: 10px;
    position: absolute;
    top: 24px;
    width: 2px;
  }
  .work-order-item:not(:last-child) {
    margin-bottom: 20px;
  }
}