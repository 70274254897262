.LaserPriceList-container {
  text-align: center;
  padding: 120px 80px;
  max-width: 1600px;
  margin: 0 auto;
}

.LaserPriceList-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;

  color: #182129;

  margin-bottom: 30px;
}

.LaserPriceList-subtitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;

  color: #182129;

  margin-bottom: 20px;
}

.LaserPriceList-subtext {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #182129;

  margin-bottom: 50px;
}

.LaserPriceList-table {
  max-width: 1600px;
  margin: 0 auto;
  overflow-x: auto;
}

.LaserPriceList-table-row {
  display: flex;
  flex-direction: row;
  width: 1600px;

  &.grey {
    background: #DADEE3;
  }
}

.LaserPriceList-table-row-cell {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #182129;

  padding: 20px;
  width: 205px;

  text-align: center;

  &:not(:last-child) {
    border-right: 2px solid rgba(24, 33, 41, 0.3);
  }
}

.LaserPriceList-disclaimer {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #182129;

  margin-top: 50px;
}

@media (max-width: 768px) {
  .LaserPriceList-container {
    padding: 60px 40px;
  }
  .LaserPriceList-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #182129;
    margin-bottom: 20px;
  }
  .LaserPriceList-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #182129;
    margin-bottom: 20px;
  }
  .LaserPriceList-subtext {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #182129;
    margin-bottom: 30px;
  }

  .LaserPriceList-disclaimer {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #182129;
    margin-top: 30px;
  }
  .LaserPriceList-table-row-cell {
    width: 110px;
    padding: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #182129;
  }
  .LaserPriceList-table-row {
    width:995px;
  }
}

@media (max-width: 560px) {
  .LaserPriceList-container {
    padding: 60px 20px;
  }
  .LaserPriceList-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #182129;
    margin-bottom: 20px;
  }
  .LaserPriceList-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #182129;
    margin-bottom: 20px;
  }
  .LaserPriceList-subtext {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #182129;
    margin-bottom: 30px;
  }

  .LaserPriceList-disclaimer {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #182129;
    margin-top: 30px;
  }
  .LaserPriceList-table-row-cell {
    width: 110px;
    padding: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #182129;
  }
  .LaserPriceList-table-row {
   width:995px;
  }
}
