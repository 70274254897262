.section-wishes {
  padding: 120px 80px;
}

.section-wishes-container {
  max-width: 1600px;
  margin: 0 auto;
}

.section-wishes-title {
  text-align: center;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;

  color: #182129;

  margin-bottom: 50px;
}

.section-wishes-list {
  margin: 0 auto;
  max-width: 1600px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.section-wishes-list-item {
  background: #DADEE3;
  border-radius: 20px;
  margin-bottom: 20px;

  &:not(:last-child) {
    margin-right: 25px;
  }

  max-width: 380px;
  width: 380px;
}

.section-wishes-list-item-middleware {
  padding: 24px;
  margin: 0 auto;
}

.section-wishes-list-item-num {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;

  color: #182129;
  display: flex;
  margin-bottom: 20px;
}

.section-wishes-list-item-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 136.52%;
  /* or 27px */

  color: #182129;
  margin-right: 56px;
}

@media (max-width: 1700px) {
  .section-wishes-list {
    display: grid;
    grid-template-columns: repeat(3, 380px);
    grid-gap: 20px;
    justify-content: center;
    justify-items: center;
  }
}

@media (max-width: 1250px) {
  .section-wishes-list {
    grid-template-columns: repeat(2, 380px);
  }
}

@media (max-width: 1000px) {
  .section-wishes-list-item {
    margin-bottom: 20px;
    max-width: 330px;
    width: 330px;
  }
  .section-wishes-list {
    grid-template-columns: repeat(2, 330px);
  }
}

@media (max-width: 830px) {
  .section-wishes {
    padding: 60px 40px;
  }
  .section-wishes-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: #182129;
  }

  .section-wishes-list-item-num {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #182129;
  }

  .section-wishes-list-item-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #182129;
  }
  .section-wishes-list-item {
    margin-bottom: 20px;
    max-width: 250px;
    width: 250px;
  }
  .section-wishes-list {
    grid-template-columns: repeat(2, 250px);
  }
}


@media (max-width: 600px) {
  .section-wishes {
    padding: 30px 10px;
  }
  .section-wishes-list-item-middleware {
    padding: 14px;
  }

  .section-wishes-list-item {
    margin-bottom: 10px;
    max-width: 175px;
    width: 175px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .section-wishes-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #182129;
    margin-bottom: 30px;
  }

  .section-wishes-list-item-num {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #182129;
  }

  .section-wishes-list-item-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 136.52%;
    color: #182129;
  }
}

@media (max-width: 450px) {
  .section-wishes-list-item {
    margin-bottom: 10px;
    max-width: 135px;
    width: 135px;
  }
  .section-wishes-list {
    grid-template-columns: repeat(2, 135px);
  }
}